import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Execution = _resolveComponent("Execution")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          (!_ctx.iframeTest && !_ctx.webTest)
            ? (_openBlock(), _createBlock(_component_Execution, {
                key: 0,
                isAppExecution: "",
                isAppNative: _ctx.isAppNative,
                testId: _ctx.id,
                onRedirect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goTo($event))),
                onBriefViewed: _ctx.onBriefViewed,
                appversion: _ctx.appversion,
                ref: "execution"
              }, null, 8 /* PROPS */, ["isAppNative", "testId", "onBriefViewed", "appversion"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}